import { Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import theme from '../../theme';

const FailurePage = () => {
    return (
        <div id="StationSelection" className={"StationSelection"} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div className="redirectPageText">
                <CancelIcon style={{color: `${theme.palette.primary.main}`, height: "100px", width: "100px"}} />
            </div>
            <Typography style={{ fontWeight: 'bold' }} className="redirectPageText">
                Pesanan gagal
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} id="redirectPageText" className="redirectPageText">
                Mohon coba lagi
            </Typography>
            
        </div>
    )
}

export default FailurePage;