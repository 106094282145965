/* eslint-disable */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const PopUpModal = ({open, message, error, handlePopUpClose}) => {

  return (
    <Dialog
      open={open}
      onClose={handlePopUpClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title" style={{fontFamily: `'Inter', sans-serif`}}>{error ? "OOPS!" : ''}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{fontFamily: `'Inter', sans-serif`}}>
          {message || "Something went wrong! Please try again."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePopUpClose} autoFocus className={"submitBlackButtonPopUp"} style={{fontFamily: `'Inter', sans-serif`}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
