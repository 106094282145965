/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../../theme";
import { BorderColor } from "@mui/icons-material";

const SelectStationDropDown = ({
  list,
  slideOpen,
  setOpenStationDropDown,
  setStationName,
  title,
}) => {
  const [name, setName] = useState("");
  const [stationArray, setStation] = useState([]);
  const [selectedStation, setSelectedStation] = useState();
  const [inputFocus, setFocus] = useState(false);

  useEffect(() => {
    if (name != "") {
      let sortedStationList = [];
      for (const item of list) {
        let isStationName = item.city
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        let isStationCode = item.airportCode
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        let isAirportName = item.localAirportName
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        if (isStationName || isStationCode || isAirportName) {
          sortedStationList.push(item);
        }
      }
      setStation([...sortedStationList]);
    }
    if (name == "") setStation([...list]);
  }, [name]);

  // store all station names namesArray.

  useEffect(() => {
    if (selectedStation) {
      setStationName(selectedStation);
      setOpenStationDropDown(false);
    }
  }, [selectedStation]);

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        background: "rgba(0,0,0,0.5)",
        display: slideOpen ? "" : "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "25px",
          bottom: "0",
          right: "0",
          left: "0",
          margin: "auto",
          maxWidth: "640px",
          borderRadius: "16px 16px 0 0",
          background: "#FFF",
          display: slideOpen ? "" : "none",
        }}
      >
        <Slide
          direction={"up"}
          in={slideOpen}
          className={"sliderStationList"}
          id="slider-container"
        >
          <Paper
            id="slider-paper"
            style={{ height: slideOpen ? "100%" : "0%" }}
          >
            <div style={{ padding: "16px", paddingTop: "0", height: "100%" }}>
              <div className={"selectStationSliderHeader"}>
                <Typography
                  children={title}
                  className="popUpHeaderText"
                  id="headerText"
                />
                <IconButton
                  onClick={() => {
                    setName("");
                    setOpenStationDropDown(false);
                  }}
                >
                  {" "}
                  <CloseIcon style={{ color: "#1A1A1A" }} />{" "}
                </IconButton>
              </div>

              <TextField
                fullWidth
                variant="standard"
                placeholder="Cari kota atau bandara"
                onChange={(e) => setName(e.target.value)}
                autoComplete={"off"}
                value={name}
                autoFocus
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                InputProps={{
                  disableUnderline: true,
                  className: inputFocus
                    ? "searchAirportFieldOnFocus"
                    : "searchAirportField",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        fontSize="medium"
                        style={{ color: theme.palette.primary.main }}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <div className={"stationlistContainer"}>
                {stationArray.map(
                  (
                    {
                      localAirportName,
                      city,
                      airportCode,
                    },
                    i
                  ) => (
                    <div
                      key={i}
                      className={"stationNameBlock"}
                      onClick={() => {
                        setName("");
                        setSelectedStation(stationArray[i]);
                      }}
                    >
                      <div>
                        <Typography
                          children={`${city}`}
                          className={"stationName"}
                        />
                        <Typography
                          children={localAirportName}
                          style={{ fontSize: "12px", color: "#1A1A1A" }}
                        />
                      </div>
                      <Typography
                        children={airportCode}
                        className={"stationStatus"}
                        style={{
                          background: "rgb(242 108 108 / 14%)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </Paper>
        </Slide>
      </div>
    </div>
  );
};

export default SelectStationDropDown;
