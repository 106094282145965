/* eslint-disable */
import React from "react";
import "../../Styles/Spinner.css";

import { Dialog, Typography } from "@mui/material";

const RedirectPage = () => {
  return (
    <div id="StationSelection" className={"StationSelection"} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <div className="redirectPageText">
          <div className="loadingio-spinner-spin-yq8n5svaih"><div className="ldio-dhj58xzoplv">
            <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
          </div></div>
        </div>
        <Typography style={{ fontWeight: 'bold' }} className="redirectPageText">Mohon Tunggu</Typography>
        <Typography style={{ fontWeight: 'bold' }} id="redirectPageText" className="redirectPageText">
          Anda sedang diarahkan ke halaman lain
        </Typography>
        {/* <Typography className="redirectPageText" sx={{ fontSize: '0.90rem' }}>Proses ini akan memakan waktu kurang</Typography>
        <Typography className="redirectPageText" sx={{ marginBottom: '20px', fontSize: '0.90rem' }}>dari 1 menit</Typography> */}
    </div>
  );
};

export default RedirectPage;
