import React from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SelectDropdown = ({
  error,
  errorMessage,
  label,
  onFocus,
  onBlur,
  value,
  onChange,
  menuItems,
  isFocused,
}) => {
  const classes = useStyle();
  const isFocusedStyles = isFocused ? classes.selectDropDownFocused : classes.selectDropDown
  return (
    <>
      <Typography className={classes.selectLabel} children={label} />
      <FormControl variant="standard" fullWidth error={error}>
        <Select
          className={
            error ? classes.selectDropDownError : isFocusedStyles
          }
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          error={error}
          disableUnderline={true}
          IconComponent={() => (
            <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
          )}
          sx={{ fontWeight: "bold" }}
          onChange={onChange}
        >
          {menuItems.map((item, i) => (
            <MenuItem key={i} value={item.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {errorMessage}
        </FormHelperText>
      </FormControl>
    </>
  );
};

const useStyle = makeStyles({
  selectDropDown: {
    border: "1px solid #DDDDDD",
    borderRadius: "8px",
    padding: "5px 10px",
    "& .MuiSelect-standard": {
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selectDropDownFocused: {
    border: "1px solid #1A1A1A",
    borderRadius: "8px",
    padding: "5px 10px",
    "& .MuiSelect-standard": {
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selectDropDownError: {
    border: "1px solid #d32f2f",
    borderRadius: "8px",
    padding: "5px 10px",
    "& .MuiSelect-standard": {
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selectLabel: {
    color: "#515151",
    fontWeight: "bold",
    fontSize: "14px"
  },
});

export default SelectDropdown;